<template>
  <div class="page__main__area">
    <div class="page__area">
      <v-list-item-title class="text-h5 text-capitalize page_title">
        <b>{{ $t(`nav.${$route.name.toLowerCase()}`) }}</b>
      </v-list-item-title>
      <div class="header_toolbar">
        <div class="d-flex">
          <slot name="toolbar"></slot>
        </div>
      </div>
      <div class="main_content">
        <slot name="main"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageContent",
  components: {},
  methods: {},
};
</script>
